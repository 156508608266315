/*Useful general styles for @extends*/
  .btn
    { background: $alert;//red;//$primary;
      cursor: pointer;
      display: block; 
      float: left; 
      font-size: .8em; color: #fff;
      padding: 5px 10px; 
      text-decoration: none;
      font-weight: bold;
    }
    %btn
    { background: $primary;
      cursor: pointer;
      display: block; 
      float: left; 
      font-size: .8em; color: #fff;
      padding: 5px 10px; 
      text-decoration: none;
      font-weight: bold;
    }
  section{
    .editor-field {
      button{@extend %btn; font-size: .8em; border: none;float: right; margin-top: 10px;}
    }
  }
  .altBtn{
    background: lighten($primary, 40%);
    color: $primary;
    margin-right: 5px;
  }
  a.btn:hover{background: #888; color: #fff;}
  a.btn:visited{color: #fff;}
  a.altBtn:hover,
  a.altBtn:visited{color: $primary;}
  nav ul li a{text-decoration: none;} 

  .lrg{width: 95%}
  .medlrg{float:left; width:75%;}
  .medBig{float:left; width:69%;}
  .medium{float: left; width: 49%;}
  .small{float: left; width: 37%;}
  .xsmall{float: left; width: 29.5%;
    .places &{
      width: 26%;
    }
  }
  .xxsmall{width: 17%}
  .middle{margin: 10px; padding-left:6px;}

  .fltL{float: left;}
  .fltR{float: right;}
  .cb{clear: both;}
  .cr{clear: right;}


  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border:0;
    padding:0;
  }
  .inputs{
      border: 1px $lightNeutral;
      padding: 8px;
      display: block;
      @include box-shadow(0 0 8px darken($neutral, 30%) inset);
      @include box-sizing(content-box);
  } 

.headerTreatment{
      background: $primary;
      padding-top: 20px;
      padding-bottom: 50px;
      position: relative;
      @extend .clearfix;
      &:before
      { background-size: 200% 100%;
        bottom: 0;
        background: $headerMask;
        height: 40px;
        position: absolute;
        width: 100%;}
      
  }

// Messages
.upload-message {
  color:$alert;
  padding:2px 8px;
}

// Cursor styling for toggled list items
#sortable.list.ui-sortable span {cursor:move;}
#sortable.list span {cursor:default;}
#AllItemsList, #AllTagList{
  li{cursor:default;}
  input{cursor:pointer;}
}
#seoTags {
  span {cursor:default;}
  a {cursor:pointer;}
}

// Ensures interior tabs are are not white like the index page
.edit .rightCol, .delete .rightCol, .compare .rightCol, .preview .rightCol, .create .rightCol {
  nav{
    .tabs {
      li { 
        &.ui-state-active { 
          a,a:visited {
            background:$lightNeutral;
          }
        }
      }
    }
  }
}