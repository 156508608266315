// Site Width
$siteWidth:1259px;

// Seasonal Color Section
$primary: #094A6C;
$lightPrimary: desaturate(lighten($primary, 30%), 25%);

$neutral: #e6e6e6;
$lightNeutral: lighten($neutral, 5%);
$darkNeutral: darken($neutral, 70%);
$borderNeutral: #D7D7D7;

$notification: #e07338;

// Alerts
$approved: #026634;
$alert: #990000;//#f12929;
$validationError: #F3F781;
// Fonts
$size: 1em;
$fonts: Geneva, Tahoma, Verdana, sans-serif;

// Background images - might need to be changed when theme is changed.
$innerShadow: url(/Images/admin/innershadow2.png) no-repeat;
$innerShadowRepeat: url(/Images/admin/innershadowRepeat.png) repeat-x;
$headerMask: url(/Images/admin/headerbg.png) no-repeat center bottom;
$footerMask: url(/Images/admin/footerbg.png) no-repeat center top;

//bootstrap spacing
$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$bootspacers: (0: (x: 0,
		y: 0),
	h: (x: ($spacer-x * 0.5),
		y: ($spacer-y * 0.5)),
	1: (x: $spacer-x,
		y: $spacer-y),
	2: (x: ($spacer-x * 1.5),
		y: ($spacer-y * 1.5)),
	3: (x: ($spacer-x * 3),
		y: ($spacer-y * 3)),
	4: (x: ($spacer-x * 4),
		y: ($spacer-y * 4)),
	5: (x: ($spacer-x * 5),
		y: ($spacer-y * 5)),
	auto: (x: auto,
		y: auto));