/* ==|== primary styles =====================================================
	Author: Jim Jitsu
	========================================================================== */
@import "node_modules/compass-mixins/lib/compass";
@import 
	"../ruby-compass/reset",
	//"compass/reset",
	//"compass",
	"sprites",
	"variables",
	"mixins",
	"extends",
	"jqueryuiClears",
	"scrollbar",
	"../public/timepicker-v1";
body, html{
	height: 100%;
}
body div .ui-tabs{padding: 0;} 

body{background: $neutral; font-size: $size; font-family: $fonts; }

/*This should be the ONLY fixed width on the site. All column widths are percents*/
.wrapper{width: $siteWidth; margin: 15px auto 35px;}
//stop public site wrappers from interfering with CMS styling
#widget-container .wrapper {
	width: auto;
}
/*Basic Styles*/
	input:not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio])
	{ @extend .inputs;
	}
	.lt-ie9 input[type="text"],
	input[type="url"],
	input[type="tel"],
	input[type="number"],
	input[type="color"],
	input[type="email"],
	input[type="date"],
	input[type="datetime"],
	input[type="month"],
	input[type="time"],
	input[type="password"]{
		border: 1px solid darken($neutral, 30%)
	}
	h1 
	{ color: $primary; font-size: 1.4em; 
		margin: 0;
		padding: 12px;
	}
	h2
	{ color: $primary;
		font-size: 1.2em;
		margin: 10px 0;
	}
	p{margin: 10px 0;}
	strong{font-weight: bold;}

	.activeItem{font-weight: bold; text-decoration: underline; font-size: 1.1em;}

/* Adjustment of all input fields to become smaller */
.ui-widget { font-family: Geneva, Tahoma, Verdana, sans-serif;; font-size: 1.0em; }
.ui-widget .ui-widget { font-size: 1em; }
.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button { font-family: Geneva, Tahoma, Verdana, sans-serif;; font-size: 0.8em; }
.ui-widget-content { border: 1px solid #aaaaaa; background: #ffffff; color: #222222; }
.ui-widget-content a { color: #222222; }
.ui-widget-header { border: 1px solid #aaaaaa; background: #cccccc; color: #222222; font-weight: bold; }
.ui-widget-header a { color: #222222; }



#tinymce_Content{width: 99%;}
	.spritebtn
	{ background: $neutral;
	border-left: 1px solid lighten($neutral, 10%); 
	border-top: 1px solid lighten($neutral, 10%);
	border-bottom: 1px solid darken($neutral, 20%); 
	border-right: 1px solid darken($neutral, 20%); 
	display: block; 
	float: left; 
	margin-right: 2px;
	padding-top: 0; 
	padding-left: 3px;
	padding-bottom:3px;
	width: 18px; height: 19px;
	text-align: center;
	.sprite{margin-top: 5px;}
	.sprite-search{margin-top: 4px; } 
	.sprite-edit{margin-top: 6px; margin-left:2px; }
	.sprite-delete{margin-top:7px; margin-left:3px;}
	.sprite-preview{margin-top:6px; margin-left:4px;}
	.sprite-compare{margin-top:7px; margin-left:4px;}
	}

	.toggle
	{ clear: both;
		.sprite-expand{margin-top: 15px;}
	}


/*Login Section*/

#login
	{ border: 2px solid #fff;
	width: 400px;
	margin: 70px auto 0;
	position: relative;
	header
	{ @extend .headerTreatment;
		text-align: center;
	}
	h1{ margin-top: 0;
		margin-left: 15px;
		}
	h2, p
	{ margin: 8px 25px;
		line-height: 1.4em;
	}
	a:link{color:$primary;}
	a:visited {color:$primary;}
	a:hover {color:lighten($primary, 15%);}
	a:active {color:$primary;}
	.loginForm
		{ font-size: .9em;
		margin: 0 25px 25px 25px;
		@include innerShdw;
		background-color: #fff;
		padding: 10px 30px 40px;
		p
		{ margin: 10px 0;

		}
		div
		{ margin: 5px 0;
		}
		input:not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio]){
		width: 95%; 
		}
		.editor-label {
			a:link {color:$primary;}
			a:visited {color:$primary;}
			a:hover {color:lighten($primary,20%);}
			a:active {color:$primary;}
		}
		input {border:none; font-family: Geneva, Tahoma, Verdana, sans-serif;}
		}
	.validation-summary-errors
		{ padding: 5px 25px;
		color: red;
		font-size: .8em;
		font-weight: bold;
		li{margin: 10px 0;}
		}
	.field-validation-error
		{ font-size: .9em;
		font-weight: bold;
		color: red;
		}
	footer
		{ min-height: 50px;
		padding: 30px 0 0;
		@extend .clearfix;
		&:before
		{background-size: 200% 100%;}
		p{font-size: .8em;
			color: $primary;
			line-height: 1em;}
		}
	}
.verifyAccount #login {
	width:600px; 
	margin-bottom:4em;
	p, div {
	input:not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio]).single-line {
		@include box-sizing(border-box);
		width:100%;
	}
	}
	p.actions {display:inline-block;}
	span.customStyleSelectBox{@include box-shadow(0 0 5px darken($neutral, 50%) inset);}
}  
.no-cssgradients #login header{background: $primary}
.lt-ie8 #login header:before{background: none;}
.lt-ie9{
	#login
	{ 
	.loginForm
		{ 
		input[type="text"],
		input[type="url"],
		input[type="tel"],
		input[type="number"],
		input[type="color"],
		input[type="email"],
		input[type="date"],
		input[type="datetime"],
		input[type="month"],
		input[type="time"],
		input[type="password"]{
			border: 1px solid darken($neutral, 30%);
			width: 95%;
			padding: 8px;
		}
		}
	}
}

#register{
	@extend #login;
	width: 700px;
	> p{
	margin: 10px 10px 10px 15px;
	}
	#registerInfo{
	div.white{
		@extend .loginForm;
		@extend .clearfix;
		p{
		input{padding: 8px 2.5%;}
		&.actions{
			input{
			@extend %btn;
			border: none;
			}
		}
		&.middle{margin: 10px;}
		}
	}
	}

}
.lt-ie8 #register header:before{background: none;}

/* ==|== Global Header styles ===============================================

========================================================================== */
body{min-width: $siteWidth;}
body > header   
	{ @extend .headerTreatment;
	nav 
		{ float: left; 
		margin: 10px 0 0 30px; 
		width: 30%; 
		span
			{ color: lighten($neutral, 10%);
			display: block;
			font-size: 1.2em;
			margin: 10px;
			}
		ul  
			{
			li  
				{ float: left; 
				margin: 0 8px;
				a.btn,
				a.btn:visited
					{ background: lighten($neutral, 10%);
					color: darken($neutral, 60%);
					}
				}
			}
		}
	#helpLink{float: right; font-weight: bold; font-size: .9em; text-decoration: none; color: #fff; margin-right:1.0em;
		&:visited{color: #fff;}
		}
	form  
		{ clear: right;
		float: right;
		font-size: .8em;
		margin-top: 30px;
		position: relative;
		width: 28%;
		margin-right:1.0em;
		select{
		}
		fieldset{
		}
		&#siteSelectionForm{color: $lightNeutral; clear: right; width: auto; margin-top: 20px; margin-right:1.0em;}
		#siteSearch{ 
			position: absolute; 
			right: 14.5%;
			top: 7px; 
			.siteSelect{top: -1px; right: -2px;}
		}
		input
			{ float: left;
			padding: 8px;
			width: 83%;
			margin: 0;
			@include box-shadow(0 0 5px darken($neutral, 50%) inset);
			}
		button
			{ background: lighten($neutral, 10%);
			border: none;  
			float: right; 
			margin: 0; 
			padding: 6px 4px 7px 8px; cursor: pointer;}
		}
	}
.lt-ie9 body > header form button{padding-right: 0;}
.lt-ie8 body > header:before{background: none;}
/*End Header Styles*/


/* ==|== Custom pulldown menu styles ========================================

========================================================================== */

.customStyleSelectBox {
	background: #fff;
	padding: 1px 1px 1px 12px;
	color: #333;
	font-size: 0.8em;
	font-weight: normal;
	margin: 0;
}
#siteSearch .customStyleSelectBox{
	background: #333;
	padding: 2px;
	color: #fff;
	font-size: .9em;
}
.siteSelect{padding-left: 12px;}

.customStyleSelectBox.changed {
/* You can use this if you want a different style after user has made a selection */
}

/* on the next line we add a down arrow on the right to indicate that it is a select box */
.customStyleSelectBoxInner {
background: url(/images/admin/select-arrow.png) no-repeat center right;
padding: 5px 15px 5px 1px;
min-width: 40px;
}
#siteSearch .customStyleSelectBoxInner {
background: url(/images/admin/miniarrows.png) no-repeat center right;
padding: 1px;
}
        
/* ==|== Main Navigation  ===================================================

========================================================================== */

nav.mainNav   
	{  
	ul {  margin: 0; padding: 0;
		li {
		ul {   
				li  
				{ padding: 5px 0;
					a, a:visited
					{ color: $primary;
						font-size: .9em;
					}
				}
				}
			}
		}
	> ul /*Level 1 Specific Nav styles*/
	{ margin-left: 0;
		> li 
		{ margin: 0 0 6px 0;
			background: $neutral;
			border-bottom: 1px solid $lightNeutral;
			width: 100%;
			@include fancyShadow;
			&.activeNav
			{
				> a.expdBtn, a.expdBtn:visited
				{ background: $primary;
					color: #fff;
				}
			}
		> a { color: $primary; 
				display: block; 
				float: none;
				font-weight: bold; 
				margin: 0;
				padding: 7px 10px;
				position: relative;
				&:visited{color: $primary;}
				}
		> ul{
				padding: 15px;
				margin: 0;
				@include innerShdw;
				background-color: #fff;
			}
		}
	}	
}

#treeMenu{
	li.inlineNav 
	{
		> a
		{ display: block;
		float: left; 
		margin: 0 5px; 
		}
		a.arrowUp,
		a.arrowDown{padding: 0; position: relative; top: 8px; left: 10px;}
		> a
		{ color: #000;
			font-size: .8em;
			margin-bottom: 5px; 
			text-decoration: underline;
			&:visited{color: #000;}                     
		}
	}
}

.inactiveNav ul{display: none;}

#treeNodeContainer{
	clear: both;
	padding: 1px 0 0 5px;
	border-top: 1px solid darken($neutral, 40%);
	margin-left: 0;
	margin-top: 5px;
	ul{display: block;}
	.ui-icon{
	float: left;}
	li{padding: 5px 0 0;
	ul
	{  clear: both;
		display: block;
		margin: 5px 0 0 0;
		li
		{ @extend .clearfix;
		float: none;
		clear: both;
		padding: 5px 0 3px;
		ul 
		{ margin: 5px 0 0 15px;
			li
			{    
			}
		}
		}
	}
	}
}

.expdBtn{cursor: pointer; }



#moveTreeMenu{
ul { margin-left: 15px;
	margin-top: 5px;  
	li  
		{ padding: 5px 0 2px;
		a, a:visited
			{ color: $primary;
			font-size: .9em;
			&.ui-icon{float: left;}
			}
		}
	li.inlineNav 
		{
		a
		{ display: block;
			float: left; 
			margin: 0 5px; 
		}
		a.arrowUp,
		a.arrowDown{padding: 0; position: relative; top: 8px; left: 10px;}
		> a
			{ color: #000;
			font-size: .8em;
			margin-bottom: 5px; 
			text-decoration: underline;
			&:visited{color: #000;}                     
			}
		> ul{border-top: 1px solid #666; padding-left: 0; margin-left: 0;}
		ul
		{  clear: both;
			display: block;
			margin-top: 5px;
			padding-top: 5px; 
			li
			{ @extend .clearfix;
			float: none;
			clear: both;
			padding: 5px 0;
			ul
			{ li
				{  padding-left: 10px  
				}
			}
			}
		}
		}
	}
	}


/* ==|== Interior page styles==================================================

========================================================================== */

nav.contentSort
	{ background: $primary; 
	font-weight: bold;
	clear: both;
	color: lighten($lightNeutral, 10%);
	padding: 10px;
	p{float: left; margin: 5px 10px 0;
		span{float: left; margin: 5px 5px; }} 
	p.filter, 
	.sort{float: right; font-size: .9em;}
	span {
		a {@extend %btn;
			padding: 7px 10px 8px;
			background: $lightNeutral;
			color: $darkNeutral;
			float: right;
			margin: 0 0 10px 3px;
			&:visited{color: $darkNeutral;}
		}
		}
	.upLevelBtn{
		float: left;
		a{
		@extend %btn;
		padding: 7px 10px 8px;
		background: $lightNeutral;
		color: $darkNeutral;
		&:visited{color: $darkNeutral;}
		}
	}
	.selectSort{float: right; margin-right: 10px;}
	input[type="submit"] {
		@extend %btn;
		background:$lightNeutral;
		color:$darkNeutral;
		float:right;
		border:0;
		margin:0 0 10px 3px;
	}
}
.interior .rightCol nav p.upLevelBtn{
	float: left;
}

.no-cssgradients nav.contentSort{background: $primary;};

.brandingSpace 
	{ float: left;
	margin: 20px 0 20px; 
	padding: 0; 
	text-align: center;
	width: 23.82843526608419%;
	}


.leftCol{width: 23.82843526608419%; float: left;}

.rightCol
	{ width: 74.58300238284353%; float: right;
	#widget-container &{
		width: 100%;
		.releases{
		clear: both;
		margin: 1em 0;
		}
		.releases__list-item{
		margin: 0.5em 1em;
		}
		.releases__title{
		clear: left;
		}
		.releases__item-date{
		float: right;
		font-size: 0.8em;
		color: $darkNeutral;
		margin-bottom: 0.25em;
		}
		&.media-room{
		.featuredArticles{
			width: 48%;
		}
		}
	}
	section{margin: 15px 0;}
	input:not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio])
		{ width: 94%;
		padding: 7px 3% 8px;
		margin: 5px 0 0 0;
		}
	.medium{
		input:not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio]){
		width: 87%;
		padding: 7px 6.5% 8px;
		}
	}
	.small {
		input:not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio]){
		padding:8px 9%;
		width:87%;
		}
	}
	.xsmall{
		input:not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio]){
		width: 82%;
		padding: 7px 9% 8px;
		}
	}
	.intColLeft
		{ float: left;
		padding-left: 1.27659574468085%; 
		width: 70%;// 56.59574468085106%
		}
	&.ui-tabs {
		.intColLeft.ui-tabs-panel{padding-left: 1.27659574468085%}
	}
	&.ui-widget-content .btn{color: #fff;}
	&.ui-widget-content .altBtn{color: $primary;}
	&.ui-widget-content nav.contentSort{
		a, a:link, a:visited{
		color: $darkNeutral;
		}
	}
	.intColRght
	{float: right;
		margin-right: 1.59574468085106%;
		padding: 15px 0;
		width: 25%;//37.23404255319149%;    
	}
	.intEqColLeft
	{ float: left;
		padding-left: 1.27659574468085%;
		width:47%;
	}
	.intEqColRght
	{ float:right;
		margin-right: 1.59574468085106%;
		padding: 0;
		width:47%;
	}
	.intColLeftSm{
		float:left;
		padding-left:1.27659574468085%;
		padding-top:15px;
		width:41%;
	}
	.intColRghtLrg{
		float:right;
		margin-right:1.59574468085106%;
		width:54%;
		padding:15px 0;
	}
	.intColFull {
		padding-left: 1.27659574468085%;
		margin-right: 1.59574468085106%;
		padding-top: 15px;
	}
}
.lt-ie9{
	.rightCol
	{ width: 74.58300238284353%; float: right;
	section{margin: 15px 0;}
	input[type="text"],
	input[type="url"],
	input[type="tel"],
	input[type="number"],
	input[type="color"],
	input[type="email"],
	input[type="date"],
	input[type="datetime"],
	input[type="month"],
	input[type="time"],
	input[type="password"]
		{ width: 94%;
		padding: 7px 3% 8px;
		margin: 5px 0 0 0;
		}
	.medium{
		input[type="text"],
		input[type="url"],
		input[type="tel"],
		input[type="number"],
		input[type="color"],
		input[type="email"],
		input[type="date"],
		input[type="datetime"],
		input[type="month"],
		input[type="time"],
		input[type="password"]{
		width: 87%;
		padding: 7px 6.5% 8px;
		}
	}
	.small {
		input[type="text"],
		input[type="url"],
		input[type="tel"],
		input[type="number"],
		input[type="color"],
		input[type="email"],
		input[type="date"],
		input[type="datetime"],
		input[type="month"],
		input[type="time"],
		input[type="password"]{
		padding:8px 9%;
		width:87%;
		}
	}
	.xsmall{
		input[type="text"],
		input[type="url"],
		input[type="tel"],
		input[type="number"],
		input[type="color"],
		input[type="email"],
		input[type="date"],
		input[type="datetime"],
		input[type="month"],
		input[type="time"],
		input[type="password"]{
		width: 82%;
		padding: 7px 9% 8px;
		}
	}
	}
}



.interior .rightCol
	{ background: $lightNeutral;
	@extend .clearfix;
	header, .help-field{position: relative;}


	h1
		{background: $neutral;}
	nav
		{ padding-bottom: 0;
		p
			{ float: right;
			margin: 0;
			.status{display: block; float: left;  margin-top: 5px; font-size: .8em;}
			a.btn{float: right; background: $lightNeutral; color: $darkNeutral; background-image: none; text-decoration: none;}
			}
		.tabs
			{ float: left;
			@extend .clearfix;
			li
				{ font-size: .9em;
				float: left;
				margin-right: 3px;
				a
				{ display: block;
					padding: 10px;
					background: lighten($lightPrimary, 30%);
					color: $darkNeutral;
					font-weight: 800;
				}
				&.ui-state-active
					{ a, a:visited
						{ background: none;
						color: $darkNeutral;
						}
					}
				} 
			}
		}
	.selector
		{ margin: 10px 0 10px 2%; 
		label{line-height: 1em;}
		.editor-label{margin-bottom: 5px;}
		}
	.editor-field.selector{margin: 0;}
	.stateCountry{
		.selector{margin-left: 0;}
	}
	button{
		border: none; 
		clear: both;
		cursor: pointer;
	}
	.soloBtn{
		padding-top: 10px;
		font-size: .8em;
		.btn{padding: 10px;}
		}

		section, aside
		{ @extend .clearfix;
		@include box-sizing(content-box);
		table#tinymce_Content_tbl{width: 100%; padding: 0;}
		&.toggle > div{display: none;}
		.stateSelect{float: left; margin-top: 5px; position: relative;  }
		.customStyleSelectBox
			{ border: 1px $lightNeutral;
			@include box-shadow(0 0 8px darken($neutral, 30%) inset); }
		.customStyleSelectBoxInner
			{ padding: 8px 1px; }
		header
		{ border-bottom: 1px solid #fff;
			@extend .clearfix;
			h2, span, a
			{ float: left; 
				margin: 10px 10px 10px 0;
				&.sprite-expand,
				&.sprite-collapse
				{ margin-top: 15px; }
			}
			.requiredMark{float: none; margin: 0;}
			h2{
			label{margin: 0; font-size: 1em;}
			}
			label {
			float:left;
			color: $primary;
			font-size: 1.2em;
			margin: 10px 0;
			}  
		}
		.editor-field{
		h2{clear: right;}
		ul{clear: both; margin: 10px 0;}
		#btnGetVideo {
			@extend %btn;
			width: 94%;
			margin-bottom: 5px;
			box-sizing: content-box;
		}
		#LimelightId {
		width: 94%;
		box-sizing: content-box;
		}
		}
		> div
		{ @extend .clearfix;
			@include innerShdw;
			input
			{ float: left; }
			&.white
			{ background-color: #fff;
			padding: 15px; }
			h3{ color: $primary; clear: both;}
			section, aside 
			{ margin-top: 0;
				header
				{ h2, span, a
					{ float: left; 
					margin: 10px 10px 10px 0;
					&.sprite-expand,
					&.sprite-collapse
					{ margin-top: 15px; }
					}
				}
				div{ background: none;
					padding-top: 0;
					}
			}
		}
		}          
		.aliasPath{
			color: lighten($darkNeutral, 20%); 
			float: right;
			font-size: .8em; 
			margin-top: 15px; 
			label{font-weight: bold;}
			}
		.aliasPath2{@extend .aliasPath; margin-bottom:0px;float:left;}       
	.intColLeft
		{ .title
			{ @extend .clearfix;
				input{width: 70%; float: left;}
			}

		}
	.intColRght 
		{ .multi-choice-item
			{ float: left;
				font-size: .8em;
				margin: 6px 1%;
				color: darken($neutral, 40%);
				width: 48%;
			}
			ul 
			{ font-size: .8em;
				li
					{margin: 5px 1%; 
					color: darken($neutral, 40%);
					ul{font-size: 1em;}
					}
				&.twoCols
				{ li
					{ width: 46%;
						float: left;
					}
				}
			}
			select,
			textarea{
				width: 100%;
				max-width: 198px;
			}
		}
	.oneCol
		{ padding: 0 1.59574468085106% 15px;
		@extend .clearfix;
		header
			{ padding: 0 1.59574468085106%;
			span.sprite { margin-top: 13px; }
			}
		> div{padding: 0; }
		div{
			.intColLeft{
			padding-left: 0;
			section:first-child{ margin-top: 0;}
			}
			.intColRght{ 
			margin-right: 0;
			padding-top: 0;
			aside:first-child{ margin-top: 0;}
			}
		}
		}
	}
.ui-widget{ section{
	button{
		font-size: .8em;
	}
	}
}
.lt-ie9 .interior .rightCol .customStyleSelectBox{border:1px solid #666;}


#tabControls ul li {
	@extend .clearfix;
	margin-top:8px;
	font-size:0.9em;
	input[type="checkbox"] {margin-top:0px; margin-right:8px;}
	.checkbox{display: block; float: right;}
}

#listItem  {
	.toggle-unit{background:$lightNeutral $innerShadow;}
	.toggle-control { 
	display: block; 
	padding:0.4em 0 0 0;
	color:$primary;
	@extend .clearfix;
	span.fltR{margin-right: 10px;}
	.expdBtn{margin-top: 4px;}
	p:hover{
		background-color:#fff;
		.description{color:$primary;}
	}
	.catNav{
		@extend .clearfix;
		margin: 0 0 4px 0;
		padding: 6px 0 6px .5em;
		.expdBtn{margin-top: 2px;}
	}
	.catChild{
		margin-top:0.1em; margin-bottom:0.1em;
		@extend .clearfix;
		.expdBtn{margin-top: 1px;}
	}
	}
	.description{
	margin-left:0.8em; //10px;
	font-size:0.8em;
	color:$lightPrimary;
	}
	ul{ margin-left:5px;
	li{ 
		margin-left:39px;
		&.toggle{
		margin-left:20px;
		}
		ul{
		display: none;
		@extend .clearfix;
		font-size:0.9em;
		li{
			ul {font-size:0.9em;
			li{
				ul{font-size:1.0em;}
			}
			}
		}
		}
	}
	}
}

#category-list{ 
	span{cursor:default;}
	h3{margin: 7px 0;}
	ul {
	padding: 0 0 0 10px;
	margin-left:25px;
	li{
		margin: 8px 7px 8px 0px;
		input {
		margin-top:1px;
		margin-right: 3px; 
		cursor:pointer; 
		}
		ul {
		margin-left:15px;
		li{margin-left:0px;}
		}
	}
	}
	.toggle {
	margin-top:2px;
	.sprite-expand, .sprite-collapse{
		margin-top: 3px;
	}
	}
	.toggle-control{display: none;} 
	a:link {color:$darkNeutral;} 
} 

.summary textarea,
.directions textarea,
.comment textarea
	{ @extend .inputs;
	min-height: 100px; 
	width: 94%;
	padding: 8px 3%;
	} 
.lt-ie9{
	.summary textarea,
	.directions textarea,
	.comment textarea{
	border: 1px solid darken($neutral, 30%)
	}
}
.instructions
	{ margin: 10px 0;
	line-height: 1.4em;
	font-size: .8em;
	color: $darkNeutral; 
	}

.verification
	{ float: left;
	margin: 10px 0 0 10px;
	&.greenlight{color: $approved}
	&.redlight{color: $alert;}
	}

.field-validation-error
	{ color: $alert;
	display: block;
	font-weight: bold; 
	font-size: .8em;
	span{} 
	}

	//Adjusting height on article and blog edit pages
.blogarticles .mceIframeContainer iframe {
	height: 700px !important;
}


//TRight Corner Action Buttons

.rightCol{
	.actions
	{ 
		@extend .clearfix;
		clear: both;
		float: right;
		input 
		{ 
			box-sizing: content-box;
			@extend %btn; 
			border: none;
			cursor: pointer; 
			margin: 0 3px 0 0; 
			padding: 3px 8px 5px;
		}
		a
		{ 
			@extend %btn;
			font-size: .8em; 
			color: #fff;
			margin-right: 3px;
			padding: 5px 8px 6px 8px;
			&:last-child{@extend .altBtn; float: right;margin-right: 0;}
		}
		span
		{ 
			float:left;
			padding:6px 10px 0 0;
			color:$alert;
			font-size:.8em;
		}
		.previewBtn__Accented
		{
			background: #EF952F;
		}
	}
}
.lt-ie8 .rightCol .actions{
	width: 45%;
}


/* Assorted Sidebar Item styles */
.ui-dialog{ 
	#list-view-all, 
	#tags-view-all{
		float: none;
		ul { 
		font-size: .8em;
		li{
			//margin: 5px 0.5%;
			color: #666;}
		&.twoCols{ 
		li{ 
			width: 46%;
			float: left; }
		}
		}
	}     
}

#list-view-all, #tags-view-all{
	float: right;
	@extend .clearfix;
	#LoadViewAll
	{ float: right; 
		margin: 15px 0 0 0;
		font-size: .8em; 
		text-decoration: none; color: darken($neutral, 40%);
		span{margin: 0 5px;}
	}
	#AllItemsList {
	div:first-child {padding-top:45px;}
	li {font-size:1.25em; color:darken($neutral,40%);} 
	} 
	#AllTagList {
	li {font-size:1.25em; color:darken($neutral,40%);}
	button{@extend %btn; border: none; margin-left: 3px; font-size: .8em;}
	}  
	#viewAllFilter {padding-left:8px; margin-left:8px;}
	.pagingFilter {
	position: relative;
	float: right;
	top: 10px;
	margin-bottom:8px;
	li{float: left;}
	.pagingbtn {
		@extend %btn; 
		margin-left: 2px;
		padding:3px 5px;
		font-size:0.7em;
	}
	.activeItem { 
		@extend .altBtn;
		margin-right: 0;
	}
	} 
}

#dialog {
	.detailsButton {
		line-height:2.0em;
		font-size:0.9em;
	} 
	a#cancelButton{
	@extend %btn;
	margin-right:5px;
	margin-top:15px;
	&:hover{ background: $lightPrimary;}
	}
	a#continueButton{
	@extend %btn;
	margin-right:5px;
	margin-top:15px;
	&:hover{ background: $primary;}
	}
}

#PlaceListing {
	.ui-tabs-panel{padding: 0;}
	table{
	tr:first-child{background-image: none;}
	}
} 

#regionSelector div {text-align: center;}
.inputList
	{ li{@extend .clearfix;}
	}

.validation-summary-errors{
	margin: 10px 10px 0;
	padding: 15px;
	border: 1px solid $alert;
	color: $alert;
}

.items-missing-primary-category{
	background: $neutral;
	padding: 1px 15px;
	border: 1px solid $alert;
	span{font-weight: bold; color: $alert;}
	div{ 
	padding: 15px;
	@extend .clearfix;
	p{width: 32%; font-size: .9em; float: left; margin: 5px 1% 0; }
	}
}

#dateRangeSelector{
	margin: 0px 0;
	@extend .clearfix;
	a.btn{color: #fff; margin-top: 10px;}
	.hasDatepicker{margin: 10px 0;}
	.selector {margin-top:10px;}
	.NoFlt {float:none;}
}

.formAlert {
	font-family:$fonts;
	font-size:0.8em;
	padding-left:4px;
	color:$alert;
}
.requiredMark{color: $alert;}
.stateCountry{
	div.editor-label{margin: 5px 0;}
}

.dateRange-display {
	padding-top:10px;
	font-size:0.9em;
	color:darken($neutral, 40%);
	> div {padding-left:12px;}
	.timesDays {padding-left:40px;}
}

.dayRange {
	padding:8px 10px 8px 10px;font-size:0.9em; color:darken($neutral, 40%);
	li {padding:8px 0px 4px 10px; 
	input {margin:0px 5px;}}
}



// Basic Table Styles

.rightCol 
	{
	table { 
	font-size: .8em; 
	width: 100%; 
	th{ 
		font-size: .9em; 
		padding: 15px 10px;
		&.actionRequired{width: 90px;}
		a{ color: darken($neutral, 40%); }
		}
	tr{ 
		background: $lightNeutral $innerShadowRepeat; 
		td{ 
		padding: 10px;
		color: $primary;
		}            
		}
	} 
	}
.delete .rightCol table tr:first-child{background: none;}
.places .rightCol table tr:first-child{background:none;}
.rightCol .ui-tabs-panel table tr:first-child{background: #FFF;}
.interior .rightCol table tr.noShadow{background-image: none;}
.lt-ie9 .rightCol table tr{background-position: left top;}
.lt-ie9 .rightCol table th.actionRequired {width:100px;}
#MediaUploader .qq-upload-button{
@extend %btn;
}
.versionPublished{background: #fff;}
.innerHeading {
	background-color: #fff;
	padding: 10px;
}

// Global Footer Styles

footer
	{ min-height: 150px;
	background: lighten($neutral, 10%);
	padding-bottom: 30px;
	position: relative;
	&:before
		{ content: "";
		width: 100%;
		height: 40px;
		position: absolute;
		top: 0;
		background: $footerMask;
		z-index: 1;
		}
	#footerBranding
		{
		@extend .brandingSpace;
		color: $primary;
		font-size: .8em; 
		img
			{
			margin: 40px 0;
			}
		a,
		a:visited{color: $primary;
			text-decoration: none;}
		}
	}

.lt-ie8{
	#list-view-all, #tags-view-all{width: 25%}
	.rightCol .actions input{padding: 2px 4px;}
}


// Styles for dialog wigets

#activeWidgets{clear: both;}
.ui-dialog{
	height: 500px;
	overflow: auto;
	input:not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio]){
	width: 96.7%;
	padding: 8px 1.35%;
	}
	ul{
	clear: both;
	margin: 10px;
	li{
	font-size: .8em;
	color: darken($neutral, 40%);
	@extend .clearfix;}
	}
	#widget-modal{

	.editor-field{
		margin-bottom: 10px;
		textarea{
		@extend .inputs;
		width: 96.7%;
		padding: 8px 1.35%;
		height: 50px;
		}
	}
	.editor-field--plain {
		input[type=text] {
			display: inline-block;
			height: auto;
			width: auto;
			padding: 2px;
			//box-shadow: none;
		}
	}
	#widget-html{width: 495px}
	#widget-html_tbl{width: 495px;}
	.toggle{
		margin: 10px 0;
		.expdBtn{margin-top: 3px;}
		.htmlAdd{display: none;}
		}
	.actions{
		a{@extend %btn}
	}
	button{
		@extend %btn;
		border: none;
		margin-left: 3px;
		font-size: .8em;
	}
	> header{
		@extend .clearfix;
		.title{float: left; width: 75%;}
		.active{float: right; margin-top: 22px; width: 22%;}
	}
	.sprite{
		span{
		@extend .visuallyhidden;
		}
	}
	}
	}
#widget-editor{
	> header{
	@extend .clearfix;
	.title{float: left; width: 75%;}
	.active{float: right; margin-top: 22px; width: 22%;}
	}
}

	.edit-media-item{
	background: $lightNeutral;
	margin: 10px 0;
	padding: 10px;
	@extend .clearfix;
	.actions{
	clear: right;
	float: right;
	margin: 15px 0 0 0;
	}
	> a{
	@extend %btn;
	margin-left: 3px;
	}
}
.lt-ie9{
	.ui-dialog{
	height: 500px;
	overflow: auto;
	input[type="text"],
	input[type="url"],
	input[type="tel"],
	input[type="number"],
	input[type="color"],
	input[type="email"],
	input[type="date"],
	input[type="datetime"],
	input[type="month"],
	input[type="time"],
	input[type="password"]{
		width: 96.7%;
		padding: 8px 1.35%;
	}
	}
}

// Styles for Widgets on Edit Page

.widget-item, .ui-draggable{
	cursor: move;
}

.ui-draggable-dragging p{display:none;}

#widget-list {
	.widget-item{
	float: left;
	padding: 10px;
	p{font-size: .9em; text-align: center;}
	}
}

.ui-widget{ 
	#widget-container{
	a{color: $primary;
		&.widget-remove,
		&.widget-edit{color: #fff;}
	}
	.ui-widget div button{font-size: .8em;}
	}
	}

#widget-container{
	.widget-item{
	border: 1px dotted $darkNeutral;
	@extend .clearfix;
	margin: 10px 0;
	padding: 10px;
	overflow: hidden;
	.widget-content{
		@extend .clearfix;
	}
	}
	.media-item{
	margin: .5em 0;
	}
	#zone1{float: left; width: 60%; }
	#zone2{float: right; width: 33%;}
	.ui-widget{ 
	a{color: $primary;
		&.widget-remove,
		&.widget-edit{color: #fff;}
	}
	div button{font-size: .8em;}
	}
	.infoLabel{float: left; clear: left; margin: 0;}
	.widgetInfo{float: right; clear: right;}
	.widget-content{
	display: table;
	width: 100%;
	blockquote {
		padding: 10px;
		font-style: italic;
	}
	.widgetCol{
		display: table-row;
		&:nth-child(even){
		background: $lightNeutral;
		}
		.infoLabel,
		.widgetInfo{
		display: table-cell;
		padding: .25em;
		}
	}

	}
	.floatRight {
			float: right;
			max-width: 50%;
			padding: 1em;
		img {
			max-width: 100%;
		}
	}
	.floatLeft {
			float: left;
			max-width: 50%;
			padding: 1em;
		img {
			max-width: 100%;
		}
	}
}

.widget-edit, .widget-remove{
	@extend %btn; float: right; color: #fff; margin: 3px;
	}

#widget-buttons button{font-size: .8em;}

#widget-jail{
	background: $neutral;
	padding: 20px;
	p{color: $darkNeutral; text-align: center;}
} 



// Media List Widget Styles

.media-image{float: left; margin: 0 10px 10px 0; width:50%;}
.imageInfo{
	float: right;
	font-size: .9em;
	width: 48%; 
	tr{
	td{
		padding: 5px 5px 5px 0;
	}
	} 
}
.media-list .mediaInfo{
	div{}
}
.ui-dialog .media-list{min-width: 500px;}
.media-item{@extend .clearfix;}
.edit-media-item{
	.actions{
	a{margin-right: 3px;}
	}
}



// Listing widget styles

#listing-main-view{
	@extend .clearfix;
	div,section{
	a.btn{color: #fff; float: right;margin: 10px 4px 0;}
	}
}
.listing {
	ul li{
	@extend .clearfix; padding: 3px 0; 
	}
	ul li:hover{background: #fff;}
	ul li a.btn, div a.btn{color: #fff; float: right;margin: 0px 4px 0;}

	.toggle .associations{
	display: none;
	div{
		padding-bottom: 5px; margin-bottom: 5px; border-bottom: 4px solid $lightNeutral;
	}
	}
	.listingCatAtt div{
	@extend .clearfix; 
	padding: 7px; 
	margin: 10px 0; 
	background: lighten($lightPrimary, 35%);
	p{margin-top: 0;}
	}  
	}

.ui-dialog #listing-category-edit { 
	@extend .clearfix; 
	ul {
	@extend .clearfix;
	li ul{
		margin: 0 8px;
		li{font-size: 1em;}
		}
	}
	}

#selectAttribute{width: 55%; float: left; select {@include box-sizing(border-box); width:100%;}}

#selected-attribute-list{
	width: 40%; float: right; font-size:0.8em;
	}

.radioBtns{margin: 10px 0;clear:left;}

.ui-dialog {
	#listing-attributes-edit{
	@extend .clearfix;
	background: lighten($lightPrimary, 35%);
	padding: 10px;
	div:first-child{@extend .clearfix;}
	.addField{
		@extend .clearfix;
		clear: right;
		input.add {width: 85%; float: left;}
		a.btn{float: right;padding: 11px; margin: 3px 0;}
		}
	.listing{margin: 10px 0;}
	}
	}
.editPage {
	.ui-dialog .ui-dialog-content{
	padding: .5em 0 1em 1em;
	.scroll-pane {}
	#widget-editor{margin-right: 1em;}
	}

}

.ui-dialog{
	.jspScrollable{
	input:not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio]){
		padding: 8px 2%;
	}
	}
	#widget-modal.jspScrollable{
		.editor-field{
		textarea{
			padding: 8px 1.8%;
		}
		}
	}
}
.verifyDialog{
	ul{
	li{
		@extend .clearfix;
		border-bottom: 1px solid $borderNeutral;
		padding: 5px 0;
		.detailsButton{float: left;}
		.city{
		display: block;
		clear: left;}
		.spritebtn{float: right; margin-top: 8px;}
	}
	}
}
.lt-ie9{
	.ui-dialog{
	.jspScrollable{
		input[type="text"],
		input[type="url"],
		input[type="tel"],
		input[type="number"],
		input[type="color"],
		input[type="email"],
		input[type="date"],
		input[type="datetime"],
		input[type="month"],
		input[type="time"],
		input[type="password"]{
		padding: 8px 2%;
		}
	}
	}
}
#listingContent tr td:last-child{
	width: 14%;
}
#listingContent td a > .test {display:none;}
.toolTip{position: relative;}

// Widget Area styles

[data-type="widget-zone"]{
	min-height: 10em;
	margin: .5em 0;
	border: 1px solid darken($neutral, 20%);
	padding: 5px;
}

.generalWidget[data-type="widget-zone"],
.roughEdge[data-type="widget-zone"] {
	clear: both;
}
[data-type="widget-zone"].small {
	margin-right: .5em;
	width: 47%;
	clear: none;
}
[data-type="widget-zone"].fltR{
	float: right;
	margin-right: 0;
}
article[data-type="widget-zone"]{
	padding: 0 5px;
}

// Events Pages Styles

#eventPlacesListing {
	#list-view-all{
	.viewAll {margin:0px;}
	} 
}
#event-location {  
	.ui-widget {
	border:$neutral solid 1px;
	.ui-widget-content {border:none;}
	}    
	.tabs {
		.ui-tabs-selected {
		a {background:none;}
		}
		.ui-state-default {
		a{background:none;}
		}
		.ui-state-active {
		a{background:none;}
		}
	}
	.contentSort {
	background: $lightPrimary;
	}
	.intTab {padding:10px 15px 5px 15px;}
	.list {
	padding:0px 15px 5px 15px;
	a {text-decoration:none; color:$darkNeutral;}
	.ui-icon {display:inline-block;}
	}
	button {margin-top:27px;}
}

.verifyBtn {  
	span {
	float:right;
	padding:8px 10px 0 0;
	a {
		@extend %btn;
		font-size: 0.8em; 
		margin-right: 3px;
		padding: 5px 8px 6px 8px;    
	}
	a:hover { background: $lightPrimary;}
	}
}


#AllItemsList button {
	@extend %btn;
	font-size:0.8em;
	border:none;
	clear:both;
	margin-top:15px;
}

.btnScroll {
	position:relative;
	width:940px; 
	z-index:1;
}
.btnScroll.fixed { 
	position:fixed;
	top:0;
	border-bottom: 2px solid $primary;
}

.placeInfo {
	.help-field {
	padding: 10px 0 5px;
	@extend .clearfix;
	label{
		float: left;
		margin-right: 10px;}
	}
}
.lt-ie8 .placeInfo .help-field{clear: both;}
.pagingContainer {
	position: relative;
	float: right;
	top: 30px;
	li{float: left;}
	.pagingbtn {
	@extend %btn; 
	margin-left: 2px;
	padding:3px 5px; 
	font-size:0.7em;
	}
	.activeItem { 
	@extend .altBtn;
	margin-right: 0;
	}
}


// Help Text

.helpText {
	display:none;
	position:absolute;
	z-index: 20;
	width: 250px;
	padding: 5px 15px;
	background: $primary;
	color: #fff;
	font-size: 0.8em;
	li {margin-top:8px;}  
}
	.arrowDown:after{
	content: " "; 
	display: block; 
	width: 0; 
	height: 0;
	border-left: 10px solid transparent;           
	border-right: 10px solid transparent;
	border-top: 15px solid $primary;
	position: absolute;
	bottom: -13px; 
	left: 131px;
	z-index: 10;
	}
	.arrowUp:after{
	content: " "; 
	display: block; 
	width: 0; 
	height: 0;
	border-left: 10px solid transparent;           
	border-right: 10px solid transparent;
	border-bottom: 15px solid $primary;
	position: absolute;
	top: -13px; 
	left: 131px;
	z-index: 10;
	}
	.arrowRight:after{
	content: " "; 
	display: block; 
	width: 0; 
	height: 0;
	border-left: 15px solid $primary;;           
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	position: absolute;
	top: 25px; 
	right: -13px;
	z-index: 10;
	}

.sprite-help-ltgrey{
	display:inline-block;
}

// List filter styles

.filterToggle{margin-top: 15px;}
.filters{
	border-top: 2px solid $primary;
	padding: 15px;
	.intEqColRght,.intEqColLeft{
	label{
		font-size: 1em;
		color: $primary;
	}

	}
	.intEqColRght{
	label{display: block; margin-bottom: 3px;}
	}
	.intEqColLeft{
	h2{font-size: 1em;}
	}
	.categorySelect{
	width: 60%;
	float: left;
	padding-bottom:3em;
	select{
		width: 100%;
		min-height: 240px;
		font-size:0.8em;
	}

	}

	.filterControls{
	clear: both;
	margin-top: 5px;
	.btn{ margin-left: 3px; }
	}
	.formRow{clear: both; padding-top: 10px;}
}
.lt-ie9 .actionBtnHolder{
	width: 13%;
}

.seo__publish {

	header {
		h2 {
			float: left;
		}
	}
	.publish__button {
		box-sizing: content-box;
		@extend %btn; 
		border: none;
		cursor: pointer; 
		margin: 0 3px 0 0; 
		padding: 3px 8px 5px;
	}
	.publish__message {
		float:right;
		padding-top: 8px;
	}
}

@import "nick";

/* ==|== non-semantic helper classes ========================================
Please define your styles before this section.
========================================================================== */

	/* For image replacement */
	.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; *line-height: 0; }
	.ir br { display: none; }

	/* Hide from both screenreaders and browsers: h5bp.com/u */
	.hidden { 
		display: none !important; 
		//visibility: hidden; 
	}

	/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
	.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }

	/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */
	.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }

	/* Hide visually and from screenreaders, but maintain layout */
	.invisible { visibility: hidden; }

	/* Contain floats: h5bp.com/q */
	.clearfix:before, .clearfix:after { content: ""; display: table; }
	.clearfix:after { clear: both; }
	.clearfix { *zoom: 1; }
	
	.contrained-image {
		max-width: 100%;
	}

.filter__select {
	border:1px solid #f3f3f3;
	margin-top:4px; 
	padding:7px 3% 6px 6.5%;
	@include box-shadow(0 0 8px #9a9a9a inset);
}
.media-image a img {max-width:100%; height:auto;}

.widget-placeholder {
	border: 1px dashed #ccc;
	width: 83px;
	height: 72px;
}

#widget-list .widget-item {
	width: 84px;
}

.locations-grid {
	//display: flex;
	margin-top: 20px;
	margin-left: 20px;
}

.location-container{
	//width: 20%;
	//display: inline-block;
	vertical-align: top;
	padding: 10px;
}

.location {
	margin: 2px 0;
	//desperate times
	padding-top: 4px !important;
	font-size: .9em;
	& + & {
		border-top: 1px solid #ccc;
	}
}

.location__input {
	max-width: 50%;
	& + button, & + select {
		margin-top: 10px;
		margin-left: 10px;
	}
}

.location__name {
	display: inline-block;
}

.location__checkbox {
	float: right !important;
}

.city-header{
	display: block;
	padding: 10px;
}

.city-name-header__button {
	margin-top: 10px;
	cursor: pointer;
}

.bubble-container {
	position: relative;
}

.bubble {
	width: 27px;
	height: 27px;
	border-radius: 50%;
	background-color: $notification;
	color: #fff;
	position: absolute;
	right: -0px;
	top: -15px;
	text-align: center;
	line-height: 25px; 
	font-size: .75em;
}

.alert {
	color: $alert;
}


.b-red{
    border: 1px solid red;
}
.b-blu{
    border: 1px solid blue;
}
.b-grn{
    border: 1px solid green;
}



//multiselect sortable
//https://www.jqueryscript.net/form/sortable-multi-select.html#google_vignette
.multiselect_sortable_content{
	.selection_title,
	.selectable_title{
		margin-bottom: .35rem;
	}
	.selection_content {
		&.sortable {
			.select_li {
				cursor: grab;
			}
		}
	}
	.selectable_content{
		.select_li {
			cursor: default;
		}
	}
}

.text-small{
	font-size: 80%;
}

.mb-2{
	margin-bottom: .5rem;
}