@mixin fancyShadow{
    position: relative;
  &:before,
  &:after {
    content:"";
    position:absolute;
    z-index:-1;
    bottom:15px;
    left:10px;
    width:47%;
    height:20%;
    @include box-shadow(0 16px 8px darken($neutral, 20%));
    @include rotate(3deg);
    }
  &:after{
   right:10px;
   left:auto;
   @include rotate(-3deg);
  }
}

@mixin innerShdw
  { background: $innerShadow;
    background-size: 100% 8px;
    padding-top: 15px;
  }  



//bootstrap spacing
//https://codepen.io/danielmorales/pen/rmmRpv
@mixin bootstrapSpacing() {
	@each $prop, $abbrev in (margin: m, padding: p) {
	  @each $size, $lengths in $bootspacers {
		$length-x:   map-get($lengths, x);
		$length-y:   map-get($lengths, y);

		.#{$abbrev}-#{$size} { #{$prop}:         $length-y $length-x !important; }
		.#{$abbrev}t-#{$size} { #{$prop}-top:    $length-y !important; }
		.#{$abbrev}r-#{$size} { #{$prop}-right:  $length-x !important; }
		.#{$abbrev}b-#{$size} { #{$prop}-bottom: $length-y !important; }
		.#{$abbrev}l-#{$size} { #{$prop}-left:   $length-x !important; }
		.#{$abbrev}x-#{$size} { 
		  #{$prop}-left:   $length-x !important; 
		  #{$prop}-right:   $length-x !important; 
		}
		.#{$abbrev}y-#{$size} { 
		  #{$prop}-top:   $length-y !important; 
		  #{$prop}-bottom:   $length-y !important; 
		}
	  }
	}
}