/*jQuery UI Tab Overwrites*/
  .rightCol .ui-corner-all, .rightCol .ui-corner-bottom,.rightCol .ui-corner-right,.rightCol .ui-corner-br {
    @include border-radius(0px 0px, 0px 0px);
  }

  .rightCol
    {
     .ui-widget-header 
      { border: none; background: none; color: transparent; }
     .ui-tabs .ui-tabs-panel 
      { background: none; border-width: 0; display: block; padding: 0; margin: 0; }
     .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active{
      border: none; }
      &.ui-tabs .ui-tabs-nav {margin: 0; padding: 0;}
      &.ui-tabs .ui-tabs-nav li { border: none; }
      &.ui-widget-content{ background: none; border: none; color: $darkNeutral; padding: 0; }
      &.ui-widget{font-family: $fonts; font-size: $size;}
      &.ui-tabs .ui-tabs-nav li.ui-tabs-selected{padding-bottom: 0;}
      &.ui-tabs .ui-tabs-panel{padding: 0}
    }