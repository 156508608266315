// Scroll Bar Styling
// CSS Styles that are needed by jScrollPane for it to operate correctly.
// Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
// may not operate correctly without them.
div{outline: 0 none;}
.jspContainer:focus{
  border: none;
  outline: 0 none;}

.jScrollPaneContainer, .jScrollPaneContainer:focus{
    outline: 0 none;
}

.jspContainer
{
  overflow: hidden;
  position: relative;
  outline: 0 none;
}

.jspPane
{
  position: absolute;
}

.jspVerticalBar
{
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background: red;
}

.jspHorizontalBar
{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16px;
  background: red;
}

.jspVerticalBar *,
.jspHorizontalBar *
{
  margin: 0;
  padding: 0;
}

.jspCap
{
  display: none;
}

.jspHorizontalBar .jspCap
{
  float: left;
}

.jspTrack
{
  background: lighten($lightPrimary, 35%);
  position: relative;
}

.jspDrag
{
  background: $lightPrimary;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag
{
  float: left;
  height: 100%;
}



.jspCorner
{
  background: #eeeef4;
  float: left;
  height: 100%;
}

/* Styles specific to the scroll bar */
.scroll-pane
{
  width: 100%;
  height:100%;
  overflow: auto;
}
.horizontal-only
{
  height: auto;
  max-height: 200px;
}