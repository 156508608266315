/*SPRITES!*/
  .sprite
    { background: url('/Images/admin/cms-icons.png') no-repeat;
      display: block; 
      float: left;
      margin-right: 5px;}

  /*.active .sprite,*/
  /*.activeNav .sprite
  /*  { background: url('/Images/admin/cms-icons-white.png') no-repeat; }*/

  .sprite-add {
    background-position: -31px -214px;
    width: 14px;
    height: 14px;
  }
  .sprite-admin {
    background-position: -1px -368px;
    width: 18px;
    height: 17px;
    margin-left: 1px;
    padding-right: 3px;
  }
  .activeNav .sprite-admin{
    background-position: -54px -368px;
  }
  .sprite-admin-collapse {
    background-position: -31px -291px;
    width: 17px;
    height: 9px;
  }
  .sprite-admin-expand {
    background-position: -31px -258px;
    width: 17px;
    height: 10px;
  }
  .sprite-ads {
    background-position: 0px -302px;
    width: 16px;
    height: 17px;
    margin-left: 3px;
    padding-right: 3px;
  }
  .activeNav .sprite-ads{
    background-position: -53px -302px;
  }
  .sprite-approve {
    background-position: -30px -156px;
    width: 10px;
    height: 8px;
  }
  .sprite-deactive {
    background-position: -83px -151px;
    width:16px;
    height:17px;
  }
  .sprite-articles {
    background-position: -3px -235px;
    width: 13px;
    height: 16px;
    margin-left: 2px;
    padding-right: 7px; 
  }
  .activeNav .sprite-articles{
    background-position: -57px -235px;
  }
  .sprite-badges {
	background-position: -1px -419px;
	width: 21px;
	height: 20px;
  }
  .activeNav .sprite-badges {
	background-position: -41px -419px;
  }
  .sprite-blog {
    background-position: -1px -270px;
    width: 17px;
    height: 16px;
    margin-left: 1px;
    padding-right: 4px;
  }
  .activeNav .sprite-blog{
    background-position: -52px -270px;
  }
  .sprite-cities {
    background-position: 0px -32px;
    width: 19px;
    height: 18px;
    margin-left: 0px;
    padding-right: 3px
  }
  .activeNav .sprite-cities{
    background-position: -53px -32px;
  }
  .sprite-collapse {
    background-position: -30px -173px;
    width: 14px;
    height: 14px;
    margin-top: 2px;
  }
  .sprite-deals
    {background-position: -2px 0;
    width: 17px;
    height: 18px;
    margin-left: 2px;
    padding-right: 3px;
  }
  .activeNav .sprite-deals{
    background-position: -53px 0;
  }
  .sprite-delete {
    background-position: -32px -351px;
    width: 10px;
    height: 10px;
  }
  .sprite-edit {
    background-position: -29px -322px;
    width: 13px;
    height: 12px;
  }
  .sprite-events {
    background-position: -1px -67px;
    width: 19px;
    height: 18px;
    margin-left: 0px;
    padding-right: 4px;
  }
  .activeNav .sprite-events{
    background-position: -53px -66px;
  }
  .sprite-expand {
    background-position: -30px -190px;
    width: 14px;
    height: 14px;
    margin-top: 1px;
  }
  .sprite-help-grey {
    background-position: -27px -67px;
    width: 18px;
    height: 19px;
    padding-right:4px;
  }
  .sprite-help-white {
    background-position: -28px -32px;
    width: 18px;
    height: 18px;
  }
  .sprite-help-ltgrey{
    background-position: -79px -67px;
    width: 21px;
    height: 20px;
  }
  .sprite-house {
    background-position: -27px -101px;
    width: 20px;
    height: 18px;
    padding-right: 1px;
  }
  .activeNav .sprite-house{
    background-position: -78px -101px;
  }
  .sprite-lists {
    background-position: -2px -203px;
    width: 15px;
    height: 12px;
    margin-left: 3px;
    padding-right: 4px;
  }
  .activeNav .sprite-lists{
    background-position: -54px -203px;
  }
  .sprite-media {
    background-position: -2px -333px;
    width: 18px;
    height: 18px;
    margin-left: 1px;
    padding-right: 3px;
  }
  .activeNav .sprite-media{
    background-position: -53px -333px;
  }
  .sprite-new {
    background-position: -26px -1px;
    width: 20px;
    height: 14px;
  }
  .sprite-places {
    background-position: -4px -100px;
    width: 13px;
    height: 19px;
    margin-left: 3px;
    padding-right: 6px;  
  }
  .activeNav .sprite-places{
    background-position: -57px -100px;
  }
  .sprite-pressrel {
    background-position: -1px -134px;
    width: 18px;
    height: 14px;
    margin-left: 1px;
    padding-right: 3px;
  }
  .activeNav .sprite-pressrel{
    background-position: -53px -134px;
  }
  .sprite-remove {
    background-position: -30px -236px;
    width: 13px;
    height: 13px;
    margin-top: 1px;
    padding-right: 4px;
  }
  .sprite-search {
    background-position: -29px -124px;
    width: 17px;
    height: 20px;
  }
  .sprite-seasonal {
    background-position: -1px -169px;
    width: 24px;
    height: 14px;
  }
  .activeNav .sprite-seasonal{
    background-position: -53px -169px;
  }
  .sprite-tree-collapse {
    background-position: -29px -291px;
    width: 17px;
    height: 9px;
  }
  .sprite-tree-expand {
    background-position: -29px -258px;
    width: 17px;
    height: 10px;
  }
  .sprite-membership{
    background-position: -1px -392px;
    width:18px;
    height:17px;
    padding-right:4px;
  }
  .sprite-membership-white{
    background-position:-55px -390px;
    width:18px;
    height:18px;
  }
  .sprite-preview{
    background-position: -85px -372px;
    width:10px;
    height:11px;
  }
  .sprite-compare {
    background-position:-30px -399px;
    width:9px;
    height:10px;
  }
  .sprite-compare-white{
    background-position:-85px -399px;
    width:9px;
    height:10px;
  }
  .sprite-poll {
  	background-position: 0px -450px;
  	width:25px;
  	height:25px;
  }
  .sprite-ticker {
    background-position: 0px -478px;
    width:25px;
    height:25px;
  }

  .rSprite{
    @extend .sprite;
    float: right;
    margin-right: 0;
    margin-top: 4px;
  }
  .lt-ie8 .rSprite{position: absolute; right: 10px; top: 8px;}

  .inactiveNav .rSprite{
    background: url('/Images/admin/cms-icons.png') no-repeat -29px -258px;
    width: 17px;
    height: 10px;
  }
  .activeNav .rSprite{
    background: url('/Images/admin/cms-icons.png') no-repeat -29px -291px;
    width: 17px;
    height: 9px;
  }
  
  .sprite-randomizer {
	background-position: -78px -418px;
    width:23px;
    height:23px;
  }