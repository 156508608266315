/*Nick's SCSS - to be added to relevant SCSS files later*/
.popUp 
  {@extend .rightCol;
    table{
      tr{
        td:first-child{width: 55px;}
      }
    }
    .insertbtn{
      @extend .spritebtn;
      width: 40px; 
    }
    .sprite-insert{
      display: block; 
      float: left;
      margin-top:5px;
      margin-left: 2px;
      color:$primary;
    } 
  }

section.popUp{
  float: none; 
  margin: 0 auto; 
  width: 95%;
  #listingContent {
    tr td:last-child{width:20%;}
  }
}



.rightCol {
  .displayModel{
      color: $primary;
      margin-left:15px;
  }
}

.rtBox {
  @extend .fltR;
  margin-right:8px;
} 

.items-missing-county {
  @extend .validation-summary-errors;
}

.groomed {
  @extend .fltR;
  margin-top:28px; 
  @extend .clearfix;
}

.county-label {
  margin-bottom:5px;
}

.feedback {
  padding-top:15px;
}

.phoneType {
  @extend .medium;
  width:48%;
  padding-top:20px;
}

.input-validation-error{
  background-color:$validationError;
}
.compareHighlight{
  @extend .input-validation-error;
  border-top: 1px solid $borderNeutral;
}
#compareTable{
  margin-top: 10px;
  tr{
    td{vertical-align: top;}
  }
  a {
      word-break: break-word;
  }
}

.compareHeader {
    border-top: 1px solid $borderNeutral;
    td{
      background: #fff;
      font-weight: bold;
    }
  }
// Multi-choice styles
.multi-choice-item {
  font-size:0.9em;
  color:darken($neutral, 40%);
  padding-top:7px;
  input[type="checkbox"] {margin-top:0px; margin-right:8px;}
}
.filter-multi-choice {
  font-size:0.9em;
  color:darken($neutral, 40%);
  padding-top:2px;
  padding-bottom:2px;
  vertical-align:top;
  input[type="checkbox"] {margin-top:2px; margin-left:10px;}
}
#seoTags {
  ul li {
    margin-top:7px;
    span {font-size:0.9em; color:darken($neutral, 40%);}
    }
}
//global list class (also nested in events #event-location)
.list {
  color:darken($neutral, 40%); font-size:0.9em;
  li {margin-top:7px;}
}

//For table on versions tabs
.nowrap {white-space:nowrap;}
.textRight {text-align:right;}

//Media Upload 
.MediaFileDetails { 
  width:300px;
  float:right;
}

.edit-media-item {
  border:1px dashed $darkNeutral;
}

.MediaFileNoDetails {
  margin-top:10px;
  width:50%;
  float:right;
}
#upload-message {
  font-size:0.9em;
  color:$alert;
  padding-top:5px;
  padding-left:135px;
}
#MediaFileList{ 
  li{ 
      margin: 15px 10px;
      @extend .clearfix;
      img{float: left;}
  }
  .grid-media-item {
    border:solid 1px $neutral;
    margin:8px;
    background-color:$neutral;
    padding:10px;
    img {padding-left:15px; padding-top:15px;}
    .summary textarea {
      @extend .inputs;
      min-height:50px;
      width:94%;
      padding:8px 3%;
    }
  }
}
.v-status {padding-left:25px;}
.previewImage {margin-top:15px;}
.previewContent {
  margin-top:20px;
  div {
    padding-left:15px;
    }
  label {color:$primary;}
  .insertImage {margin-top:25px;}
}

.dashboard {
  .rightCol {
    background:none;
    .dashboardModule {padding-top:30px;}
    div:first-child {padding-top:0px;}
    .pagingContainer {
      position: relative;
      float: right;
      top: 15px;
      li{float: left;}
      .pagingbtn {
        @extend %btn; 
        margin-left: 2px;
        padding:3px 5px;
        font-size:0.7em;
      }
      .activeItem { 
        @extend .altBtn;
        margin-right: 0;
      }
    }
  } 
}

#approvalTabs, #mylistingTabs { 
  .contentSort {
    background: $primary; 
    font-weight: bold;
    clear: both;
    color: lighten($lightNeutral, 10%);
    padding: 10px 10px 0px 10px;
    p {
      float: left; margin: 5px 10px 0;
      span {
        float: left; margin: 5px 5px; 
        }
      } 
    p.filter, 
    .sort{float: right; font-size: .9em;}
    span {
      a {@extend %btn;
        padding: 4px 10px 5px;
        background: $lightNeutral;
        color: $darkNeutral;
        float: right;
        margin: 3px 0 10px 3px;
        &:visited{color: $darkNeutral;}
      }
    }
    .selectSort{float: right; margin-right: 10px;}
    input[type="submit"] {
      @extend %btn;
      background:$lightNeutral;
      color:$darkNeutral;
      float:right;
      border:0;
      margin:0 0 10px 3px;
    }
  }
  .tabs { 
    @extend .clearfix;
    li { 
      font-size: .9em;
      margin-right: 3px;
      a { 
        display: block;
        padding: 10px;
        background: lighten($lightPrimary, 30%);
        color: $darkNeutral;
        font-weight: 800;
      }
      &.ui-state-active { 
        a, a:visited { 
          background: none;
          color: $darkNeutral;
        }
      }
    } 
  }
}

// Ensures interior tabs are are not white like the index page
.edit .rightCol, .delete .rightCol, .compare .rightCol, .preview .rightCol, .create .rightCol, .help .rightCol, .workflow .rightCol, .reports .rightCol, .account .rightCol, .medialibrary .rightCol, .ads .rightCol {
  nav{
    .tabs {
      li { &.ui-state-active { a,a:visited {background:$lightNeutral;}}}
    }
  }
}
.dragHighlight {
  border:1px dashed $darkNeutral;
}

//Theme Styling
.themeBox {
  margin: 8px;
  padding:5px;
  a {margin:1px 2px;}
  img {padding:5px;}
  @extend .white;
  a.btn {margin-top:2px;}
  p {line-height:1.2em;}
}
.activeTheme {
  img {padding:5px 9%;}
  p {line-height:1.2em;}
}

.helpDetails {
  padding-left:8px;
}

ul#sortable 
{
  .adWeight {
    width:97%;
    display:inline-block;
    margin-bottom:3px;
    padding:12px;
    background-color:$lightNeutral;
  }
  li img {padding:4px;}
  li span {padding:2px;}
  .weight {float:left; padding-top:13px; padding-right:15px;}
}

//Fix for Login page centering
.loginpage {min-width:100%;}
.touch {
  .loginpage{
    input {background-color:$neutral;}
  }
}


// Styling for addition of results to table.
div.showingResults {
  background:#fff; padding:0.75em 1.0em 0 1.0em; color:$primary;}

// Office Use Filters
.officeUseFilters {
  clear:both;
  border:1px dashed $primary;
  padding:0.75em;
  margin:0.75em;
  display:inline-block;
  color:$primary;
  font-size:0.9em;
  div.editor-field {
    width:22%; 
    float:left; 
    margin:1em 1.5%;
    section {margin-top:5px;}
    textarea {margin-top:5px;}
  }
}

//Snow Conditions Styling
div.snowBase {
  clear:both; 
  width:100%;
  display:block;
  input#SnowBaseMin, input#SnowBaseMax {float:none; display:inline; margin-bottom:1em; width:15%; @include box-sizing(border-box);}
}

div.snowCondition {
  p {margin:0; label {display:block; margin-bottom:5px;}}
}

div.snowStatus {
  select[disabled] + span {background:#999;}
}

.reports {
  .groomed {
    margin-top:20px; 
    label {float:left;
      &:after {content:":"}
    }
    span {float:left;}
  }
}
.events .eventEditor textarea {width: 100%; height:12em;} 

button.revert {
  background:$primary;
  cursor:pointer;
  text-decoration:none;
  font-weight:bold;
  font-size:0.9em;
  color:#fff;
  padding:6px 10px;
  margin-top:5px;
}
